.user-settings-container {
    padding: 20px;
  }
  
  .profile-picture-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .edit-icon {
    position: absolute;
  }
  
  .avatar-editor {
    position: absolute;
    top: 10px;
    right: 20px;
    background: white;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .profile-picture-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  