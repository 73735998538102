.custom-time-bar {
    width: 100%;
    position: relative;
}

.buttons-container {
    display: flex;
    justify-content: space-between; /* Distribute space between left and right button groups */
    margin-bottom: 10px;
}

.left-buttons,
.right-buttons {
    display: flex;
    gap: 10px; /* Add space between buttons */
}

.buttons-container button {
    background-color: #007bff; /* Primary color */
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.buttons-container button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

@media (max-width: 600px) {
    .buttons-container button {
        font-size: 24px; /* Increase icon size for mobile */
        padding: 8px 12px;
    }
}

/* Progress bar styles */
.progress-bar {
    width: 100%;
    height: 50px;
    position: relative;
    background-color: lightgray;
    overflow: hidden;
}

/* Thumbnail container styles */
.thumbnail-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

/* Thumbnail image styles */
.thumbnail-image {
    object-fit: cover;
    opacity: 0.7; /* Makes thumbnails slightly transparent */
    flex-shrink: 0; /* Prevents thumbnails from shrinking */
}

.played-region {
    height: 100%;
    background-color: blue;
    width: 0;
}

.divider {
    height: 100%;
    width: 2px;
    background-color: red;
    position: absolute;
    top: 0;
}

.tag {
    position: absolute;
    top: 0;
}

/* Subclip styles */
.subclip {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.3); /* Semi-transparent blue */
    border: 1px solid blue;
    z-index: 15;
}

.resize-handle {
    width: 10px;
    height: 100%;
    background: darkblue;
    position: absolute;
    top: 0;
    z-index: 20;
}

.resize-handle.left {
    left: -5px;
    cursor: ew-resize;
    z-index: 20;
}

.resize-handle.right {
    right: -5px;
    cursor: ew-resize;
    z-index: 20;
}

.selected {
    border: 2px solid darkblue;
}

.action-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 5px;
    z-index: 1000;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 24px;
}

.delete-button:hover {
    color: darkred;
}

/* Playhead styles */
.playhead {
    position: absolute;
    top: 0;
    width: 6px;
    height: 100%;
    background-color: black;
    cursor: pointer;
    z-index: 15;
}
