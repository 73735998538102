/* VideoList.css */

/* Container for the video list */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

/* Control panel styles */
.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* Grid size control styles */
.grid-size {
  display: flex;
  align-items: center;
}

.grid-icon {
  font-size: 24px;
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.3s;
}

.grid-icon.selected {
  color: #007bff;
}

/* Filter options styles */
.filter-options {
  display: flex;
  align-items: center;
}

.filter-options select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
}

/* Search input styles */
.search-input {
  display: flex;
  align-items: center;
}

.search-input input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
}

/* Video grid styles */
.video-grid {
  display: grid;
  grid-gap: 5px;
  padding: 1px;
}

/* Responsive column sizes based on grid size */
.video-grid.large {
  grid-template-columns: repeat(7, 1fr);
}

.video-grid.medium {
  grid-template-columns: repeat(5, 1fr);
}

.video-grid.small {
  grid-template-columns: repeat(3, 1fr);
}

/* Responsive styles for mobile view */
@media (max-width: 768px) {
  .controls {
    gap: 10px;
  }

  .video-item {
    padding: 5px;
    height: auto; /* Adjust height for mobile */
  }

  .video-container {
    height: auto; /* Adjust video container height for mobile */
  }

  .options-dropdown,
  .info-dropdown {
    top: 30px;
    right: 5px;
    width: 90%;
  }

  .video-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 5px;
    padding: 0;
  }

  /* Adjust swipe-container if needed */
  .swipe-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 999;
  }
}

/* Swipe container styles for mobile swipe mode */
.swipe-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
