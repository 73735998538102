/* VideoListItem.css */

/* General Styles for Video Item */
.video-item {
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f8f8f8;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Video Container Styles */
.video-container {
  height: 70%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* Video Details Styles */
.video-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
}

.video-details .video-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.video-details .video-info p {
  margin: 2px 0;
}

.video-details .edit-hashtags {
  display: flex;
  align-items: center;
  width: 100%;
}

.video-details .edit-hashtags input {
  width: 100%;
}

/* Dropdown and Icon Styles */
.video-item .options-icon,
.video-item .info-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 10px;
  font-size: 24px;
  color: white;
  opacity: 0.6;
  margin-right: 10px;
  z-index: 10;
}

.video-item .info-icon {
  left: 35px;
}

.options-dropdown,
.info-dropdown {
  position: absolute;
  top: 40px;
  left: 10px;
  width: auto;
  background-color: white;
  border-radius: 5px;
  opacity: 0.9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.options-dropdown button {
  display: flex;
  width: auto;          /* Allow buttons to size based on content */
  padding: 5px 10px;
  color: #000;
  cursor: pointer;
  font-size: 20px;
  text-align: left;
  background: none;
  border: none;
}

.options-dropdown button:hover {
  background-color: #f0f0f0;
}

.info-dropdown p {
  margin: 5px 0;
  width: 100%;
}

.video-item .options-dropdown .disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Error Flag Styles */
.video-item .error-flag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffcccc;
  color: #cc0000;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.9em;
  z-index: 10;
}

/* Progress Bar Styles */
.video-item .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e0e0e0;
  height: 10px;
}

.video-item .progress {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  transition: width 0.3s ease;
}

/* Loading Spinner Styles */
.video-item .loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #fff;
}

.video-item.selected {
  border: 2px solid #007bff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .options-dropdown .button-text {
    display: none;
  }

  .options-dropdown button {
    padding: 10px;
    font-size: 24px;
  }

  .video-item {
    padding: 1px;
    height: auto;
  }

  .video-container {
    height: auto;
  }

  .exit-button
  .select-checkbox
  .options-dropdown,
  .info-dropdown {
    top: 20px;
    right: 5px;
  }

}

/* Styles for Swipe Mode */
.select-checkbox {
  position: absolute;
  top: 20px;
  right: 20px;
  size: 20px;
  z-index: 15;
  opacity: 0.6;
}

.video-item.swipe-mode {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-item.swipe-mode .video-container {
  width: 100%;
  height: 100%;
}

.video-item.swipe-mode .video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-item.swipe-mode .options-icon,
.video-item.swipe-mode .info-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 24px;
  z-index: 10;
  cursor: pointer;
}

.video-item.swipe-mode .options-icon {
  right: 60px;
}

/* Add this style */
.video-container.swipe-mode {
  position: relative;
}

/* Style for the overlay description */
.overlay-description {
  position: absolute;
  bottom: 70px; /* Default bottom spacing */
  left: 20px;
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.exit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  opacity: 0.6;
  color: white;
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  padding: 5px;
  z-index: 2; /* Ensure it appears above the video and overlay */
}

.exit-button:hover {
  color: #ccc; /* Optional hover effect */
}