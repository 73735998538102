/* FriendsPage.css */
.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-button {
    padding: 0.75rem 1.25rem;
  }
  
  .accordion-body ul {
    padding-left: 1.25rem;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-input input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }
  
  li div {
    display: flex;
    align-items: center;
  }
  
  li button {
    margin-left: 0.5rem;
  }
  