.container {
    position: relative;
    margin-top: 20px;
}

.video-player-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 8vh); /* Adjust this value if your navigation bar height is different */
    position: absolute;
    top: 8vh; /* Adjust this value if your navigation bar height is different */
    left: 0;
    right: 0;
    overflow: hidden; /* Prevent scrollbars */
}

.player-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 72vh; /* 100vh - navbar: 8vh - custom-timebar: 20vh */
}

.timebar-wrapper {
    height: 20vh; /* Adjust the height of your timebar */
    background-color: #333; /* You can style the timebar background color */
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}